export const apiCall = async (endpoint, method, data) => {
    try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://amazonpricealert.web2ua.com';
        const response = await fetch(API_BASE_URL + endpoint, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
            body: data ? JSON.stringify(data) : null,
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Request failed');
        }

        return await response.json(); // Return the JSON response
    } catch (error) {
        throw new Error('An error occurred during the API call: ' + error.message);
    }
};
